import { useEffect, useState } from 'react'
import {
  MainFeatures,
  Migrate,
  OurClients,
  Footer,
  Header,
  HubspotChat,
  Testimonials,
  Plans,
  EdworkingPromotion,
  ReviewsTrust,
  TopBanner,
} from '../js/components'
import Head from 'next/head'
import Script from 'next/script'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { OrganizationSchema, WebPageSchema, BreadcrumbListSchema } from '../js/schemas'
import path from 'path'
import fs from 'fs/promises' // or 'fs' if using callbacks

export async function getStaticProps({ locale, locales }) {
  const host =
    process.env.NODE_ENV === 'development' ? process.env.LOCALHOST : process.env.CHARMELEON
  let content
  try {
    const filePath = path.join(process.cwd(), 'public', 'content', locale, 'homepage.json')

    // Read the file
    const jsonData = await fs.readFile(filePath, 'utf8')
    content = JSON.parse(jsonData)
  } catch (error) {
    console.error('error', error)
  }
  return {
    props: { content, locale, locales },
  }
}
function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export default function HomePage({ content, locale, locales }) {
  const [promotion, setPromotion] = useState(false)
  const router = useRouter()

  const onMouseOut = () => {
    const goon = localStorage.getItem('promotion')
    if (!goon) setPromotion(true)
    document.body.removeEventListener('mouseleave', onMouseOut)
  }

  useEffect(() => {
    if (
      getCookie('islogged') &&
      getCookie('islogged') !== 'false' &&
      getCookie('islogged').endsWith('edworking.com') &&
      !router.asPath.includes('redirect')
    )
      window.location = `https://${getCookie('islogged')}/`
    setTimeout(function () {
      document.body.addEventListener('mouseleave', onMouseOut)
    }, 3000)
  }, [])

  return (
    <div className="homepage">
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-166939348-2"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-166939348-2');
        `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NRW96HD');
        `}
      </Script>
      <Script id="hotjar" strategy="afterInteractive">
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2080824,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
      <OrganizationSchema />
      <WebPageSchema name={content.head.title} description={content.head.description} />
      <BreadcrumbListSchema
        items={[
          {
            name: 'Home',
            url: `https://edworking.com${locale !== 'en' ? `/${locale}` : ''}`,
          },
        ]}
      />
      <Head>
        <title>{content.head.title}</title>
        <meta itemProp="name" content={content.head.title} />
        <meta itemProp="description" content={content.head.description} />
        <meta itemProp="image" content={'/images/EdworkingPreview.png'} />
        <meta name="description" content={content.head.description} />
        <meta property="og:title" content={content.head.title} />
        <meta property="og:description" content={content.head.description} />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <meta itemProp="image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content={'/images/EdworkingPreview.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="150" />
        {locales.map((locale1, index) => (
          <link
            key={index}
            rel="alternate"
            hrefLang={locale1}
            href={`https://edworking.com${locale1 !== 'en' ? `/${locale1}` : ''}`}
          />
        ))}
        <link
          rel="alternate"
          href={`https://edworking.com${locale !== 'en' ? `/${locale}` : ''}`}
          hrefLang="x-default"
        />
        <link
          rel="canonical"
          href={`https://edworking.com${locale !== 'en' ? `/${locale}` : ''}`}
          key="canonical"
        />
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NRW96HD" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <EdworkingPromotion promotion={promotion} content={content.promotion} />
      <TopBanner content={content.topbanner} />
      <Header clean content={content.header} topbanner={true} />
      <Intro locale={locale} content={content.intro} />
      <ReviewsBanner content={content.trustworthy} />
      <OurClients className="landing" content={content.customers} />
      <Sumup content={content.sumup} />
      <MainFeatures content={content.features} />
      <Benefits content={content.benefits} />
      <div className="barcolors1" />
      <Testimonials content={content.reviews} />
      <Migrate content={content.migration} />
      <Plans content={content.pricing} landing />
      <SimilarArticles />
      <TryEdworking content={content.endbanner} />
      <Footer content={content.footer} />
      <HubspotChat />
      <div id="livenoti" className="livenoti"></div>
    </div>
  )
}
const colorsED = ['#FB8C72', '#34D7F8', '#D867C7', '#FBD067', '#47A1ED', '#4ED297', '#D5E1E4']

const ReviewsBanner = ({ content }) => {
  return (
    <div className="banner-reviews">
      <div className="banner-reviews-inner">
        {content.map((review, index) => (
          <div className="review-card" key={index} onClick={() => window.open(review.link)}>
            <Image
              width={25}
              height={25}
              src={`/images/reviews/${review.platform}.png`}
              className="platform"
              alt=""
            />
            <div className="author">
              {review.author.image !== '' ? (
                <Image width={50} height={50} src={review.author.image} alt="" />
              ) : (
                <div
                  className="profile"
                  style={{
                    background: colorsED[Math.floor(Math.random() * 7)],
                  }}
                >
                  {review.author.name.substring(0, 1)}
                </div>
              )}
              <div className="name">{review.author.name}</div>
              <div className="description">{review.author.description}</div>
              <div className="stars">
                {Array(5)
                  .fill(null)
                  .map((a, index) => (
                    <Image
                      width={16}
                      height={16}
                      key={index}
                      src={`/images/reviews/Star${
                        review.rate >= index + 1 ? 2 : review.rate >= index ? 1 : 0
                      }.svg`}
                      alt="user"
                    />
                  ))}
              </div>
            </div>
            <div className="review">{review.review}</div>
          </div>
        ))}
        {content.map((review, index) => (
          <div className="review-card" key={index} onClick={() => window.open(review.link)}>
            <Image
              width={25}
              height={25}
              src={`/images/reviews/${review.platform}.png`}
              className="platform"
              alt=""
            />
            <div className="author">
              {review.author.image !== '' ? (
                <Image width={50} height={50} src={review.author.image} alt="" />
              ) : (
                <div
                  className="profile"
                  style={{
                    background: colorsED[Math.floor(Math.random() * 7)],
                  }}
                >
                  {review.author.name.substring(0, 1)}
                </div>
              )}
              <div className="name">{review.author.name}</div>
              <div className="description">{review.author.description}</div>
              <div className="stars">
                {Array(5)
                  .fill(null)
                  .map((a, index) => (
                    <Image
                      width={16}
                      height={16}
                      key={index}
                      src={`/images/reviews/Star${
                        review.rate >= index + 1 ? 2 : review.rate >= index ? 1 : 0
                      }.svg`}
                      alt="user"
                    />
                  ))}
              </div>
            </div>
            <div className="review">{review.review}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
const SimilarArticles = () => {
  return (
    <section className="similararticles" style={{ marginTop: 40 }}>
      <div className={`sectiontop`}>
        <span className="title">Most Popular Articles</span>
        <Link href={'/blog'} className="more">
          See All Articles
        </Link>
      </div>
      <div className="articles">
        <Link
          className="single"
          href={`/blog/startups/how-to-effectively-assign-tasks-to-team-members`}
          locale="en"
        >
          <Image
            width={250}
            height={170}
            className="cover"
            src={
              'https://storage.googleapis.com/dratini/blog/small/HowToEffectiveAssignTasksToTeamMembersToIncreaseProductivity.png'
            }
            alt="Cover"
          />
          <h2 className="title">
            {'How To Effectively Assign Tasks To Team Members To Increase Productivity?'}
          </h2>
          <span className="resume">
            {
              "As a leader in the workplace, it is essential to ensure that everyone in the team gets the appropriate amount of work. Sometimes, it's tempting to give an employee more tasks than others, especially if he/she finishes the tasks faster. But keep in mind that as managers, you must be fair."
            }
          </span>
        </Link>
        <Link
          className="single"
          href={`/blog/startups/top-3-best-alternatives-to-windows-task-scheduler`}
          locale="en"
        >
          <Image
            width={250}
            height={170}
            className="cover"
            src={
              'https://storage.googleapis.com/dratini/blog/small/Top3BestAlternativesToWindowsTaskSchedulerin2022.png'
            }
            alt="Cover"
          />
          <h2 className="title">
            {'Top 3 Best Alternatives To Windows Task Scheduler In 2023'}
          </h2>
          <span className="resume">
            {
              "Handling multiple jobs and schedules can be tough, especially when you're doing them manually. To handle your everyday chores and tasks smoothly and ideally, you need a specialized program."
            }
          </span>
        </Link>
        <Link
          className="single"
          href={`/blog/startups/top-6-project-management-free-tools`}
          locale="en"
        >
          <Image
            width={250}
            height={170}
            className="cover"
            src={
              'https://storage.googleapis.com/dratini/blog/small/ProjectManagementFreeTools.png'
            }
            alt="Cover"
          />
          <h2 className="title">{'Top 6 Project Management Free Tools'}</h2>
          <span className="resume">
            {
              "We now live in a time when remote work is more prevalent than ever. This means that most of us are not spending every day in the office, but instead, we're logging in from home or our laptop, as the case may be. As such, a lot of our communication and collaboration happens online."
            }
          </span>
        </Link>
        <Link
          className="single"
          href={`/blog/startups/top-3-best-loom-alternatives`}
          locale="en"
        >
          <Image
            width={250}
            height={170}
            className="cover"
            src={
              'https://storage.googleapis.com/dratini/blog/small/Top3BestLoomAlternativiesin2022-Edworking.png'
            }
            alt="Cover"
          />
          <h2 className="title">{'Top 3 Best Loom Alternatives In 2023 - Edworking'}</h2>
          <span className="resume">
            {
              "If you didn't spend each day stuck someplace during the pandemic, you've probably used a sharing program or screen recording. There are several instances in work, school, and everyday life in which you may be required to make a video and share what's on your pc screen."
            }
          </span>
        </Link>
      </div>
    </section>
  )
}

const TryEdworking = ({ content }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch(window?.location.search)
  }, [])
  return (
    <section className="tryedworking">
      <Image
        width={750}
        height={222}
        style={{ height: 'auto' }}
        src="/images/BackgroundTryit.png"
        alt="Try Edworking"
      />
      <span>
        {content.title1}&nbsp;
        <span style={{ color: '#0FC8EF' }}>{content.title2}</span>
        {content.title3}
      </span>
      <a
        style={{ zIndex: 5 }}
        href={`https://app.edworking.com/signup/user${search}`}
        rel="noopener"
      >
        <div className="blueButton">{content.button}</div>
      </a>
    </section>
  )
}

const Benefits = ({ content }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch(window?.location.search)
  }, [])

  return (
    <section className="benefits">
      <h2 className="title">{content.title}</h2>
      <div className="benefit">
        <div className="image blue">
          <Image
            width={330}
            height={334}
            style={{ height: 'auto' }}
            src="/images/main/Tasks.png"
            alt="Tasks"
          />
        </div>
        <div className="information">
          <Image
            width={75}
            height={75}
            src="/images/main/Tasks.svg"
            style={{ padding: '23px 25px' }}
            alt="Tasks Icon"
          />
          <h2 className="title">{content.list[0].title}</h2>
          <h4 className="description">{content.list[0].description}</h4>
          <Link className="blueButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.list[0].button}
          </Link>
        </div>
      </div>

      <div className="benefit">
        <div className="information">
          <Image
            width={75}
            height={75}
            src="/images/main/Chat.svg"
            style={{ padding: '23px 25px' }}
            alt="Tasks Icon"
          />
          <h2 className="title">{content.list[1].title}</h2>
          <h4 className="description">{content.list[1].description}</h4>
          <a className="blueButton" href="https://live.edworking.com">
            {content.list[1].button}
          </a>
        </div>
        <div className="image green">
          <Image
            width={330}
            height={258}
            style={{ height: 'auto' }}
            src="/images/main/Videocall.png"
            alt="Tasks"
          />
        </div>
      </div>

      <div className="benefit">
        <div className="image yellow">
          <Image
            width={330}
            height={288}
            style={{ height: 'auto' }}
            src="/images/main/More2.png"
            alt="Tasks"
          />
        </div>
        <div className="information">
          <Image
            width={75}
            height={75}
            src="/images/main/More.svg"
            style={{ padding: '23px 25px' }}
            alt="Tasks Icon"
          />
          <h2 className="title">{content.list[2].title}</h2>
          <h4 className="description">{content.list[2].description}</h4>
          <Link className="blueButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.list[2].button}
          </Link>
        </div>
      </div>
    </section>
  )
}
const Intro = ({ content, locale }) => {
  const [scrollTop, setScrollTop] = useState(0)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY)
    }
    setSearch(window?.location.search)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className="introlanding">
      {locale === 'en' ? (
        <h1 className="title">
          {content.title1}&nbsp;
          <div className="list">
            <span style={{ display: 'flex', color: '#09323A' }}>{content.titleArray[0]}</span>
            <span style={{ display: 'flex', color: '#C26EDC' }}>{content.titleArray[1]}</span>
            <span style={{ display: 'flex', color: '#0ac9ef' }}>{content.titleArray[2]}</span>
            <span style={{ display: 'flex', color: '#05d6a2' }}>{content.titleArray[3]}</span>
            <span style={{ display: 'flex', color: '#fcb504' }}>{content.titleArray[4]}</span>
            <span style={{ display: 'flex', color: '#fd3f67' }}>{content.titleArray[5]}</span>
          </div>
          &nbsp;{content.title2}
        </h1>
      ) : (
        <h1 className="title">
          {content.title1}
          {locale !== 'it' ? <>&nbsp;</> : ''}
          {content.titleArray[0]}&nbsp;{content.title2}
        </h1>
      )}
      <h2 className="description">{content.subtitle}</h2>
      <Link href={`https://app.edworking.com/signup/user${search}`} className="blueButton">
        {content.button}
      </Link>
      <ReviewsTrust />
      {/* 
        <div className='badges'>
          <Image priority width={122} height={40} src='/images/reviews/capterra.svg' alt="Capterra Reviews" />
          <Image priority width={75} height={50} src='/images/reviews/getapp.png' alt="Get App Reviews" />
          <Image priority width={98} height={40} src='/images/reviews/softwareadvice.png' alt="Software Advice Reviews" />
          <Image priority width={93} height={120} src='/images/reviews/g21.png' alt="g2" />
          <Image priority width={93} height={120} src='/images/reviews/g22.png' alt="g2" />
        </div>
      */}
      <div className="platform">
        <Image
          priority
          width={940}
          height={540}
          style={{ height: 'auto' }}
          className="mask"
          src="/images/MaskDashboard.png"
          alt="Edworking platform overview"
        />
        <Image
          priority
          width={940}
          height={2337}
          id="front"
          style={{ height: 'auto', marginTop: -scrollTop }}
          className="front"
          src="/images/Dashboard.png"
          alt="Edworking platform overview Full"
        />
      </div>
      <div className="barcolors"></div>
    </section>
  )
}
const Sumup = ({ content }) => {
  return (
    <section className="campaign intro">
      <div className="column">
        <h2 className="title">
          {content.title1}&nbsp;
          <span className="highlight">{content.title2}</span>&nbsp;
          {content.title3}
        </h2>
        <h2 className="description">{content.description}</h2>
      </div>
      <div className="column">
        <Image
          width={445}
          height={424}
          style={{ height: 'auto' }}
          className="newway"
          src="/images/main/Allin1.png"
          alt="Front Platform"
        />
      </div>
    </section>
  )
}
