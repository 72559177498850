import Script from 'next/script'

const Organization = {
  '@type': 'Organization',
  name: 'Edworking',
  url: 'https://edworking.com/',
  logo: 'https://edworking.com/_next/image?url=%2Fimages%2FLogo.png&w=384&q=75',
  description:
    'Edworking is a platform for your team and your work. A single solution for teams with everything they need to work remotely. All your tasks, files, images and conversations in a single place and just an unique subscription. Teams use Edworking to coordinate, communicate and manage work. Our clients have transitioned from using many platforms to collaborate with their team, such as Trello and Jira for task management, Slack and Zoom for communication, or Dropbox and Drive to transfer files, to simply Edworking. This means no more switching between apps!',
  sameAs: [
    'https://t.me/edworkingapp/',
    'https://www.instagram.com/edworkingapp/',
    'https://www.linkedin.com/company/edworkingremote/',
    'https://www.facebook.com/edworkingremote/',
    'https://www.crunchbase.com/organization/ed-technology-ltd',
    'https://twitter.com/edworkingapp/',
  ],
  email: 'info@edworking.com',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Accelerator, 35 Kingsland Rd',
    addressLocality: 'London',
    postalCode: 'E2 8AA',
    addressCountry: 'United Kingdom',
  },
}
export const OrganizationSchema = () => {
  return (
    <Script
      id="app-ld-json-organization"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Edworking',
            url: 'https://edworking.com/',
            logo: 'https://edworking.com/_next/image?url=%2Fimages%2FLogo.png&w=384&q=75',
            description:
              'Edworking is a platform for your team and your work. A single solution for teams with everything they need to work remotely. All your tasks, files, images and conversations in a single place and just an unique subscription. Teams use Edworking to coordinate, communicate and manage work. Our clients have transitioned from using many platforms to collaborate with their team, such as Trello and Jira for task management, Slack and Zoom for communication, or Dropbox and Drive to transfer files, to simply Edworking. This means no more switching between apps!',
            sameAs: [
              'https://t.me/edworkingapp/',
              'https://www.instagram.com/edworkingapp/',
              'https://www.linkedin.com/company/edworkingremote/',
              'https://www.facebook.com/edworkingremote/',
              'https://www.crunchbase.com/organization/ed-technology-ltd',
              'https://twitter.com/edworkingapp/',
            ],
            email: 'info@edworking.com',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Accelerator, 35 Kingsland Rd',
              addressLocality: 'London',
              postalCode: 'E2 8AA',
              addressCountry: 'United Kingdom',
            },
          },
          null,
          '\t'
        ),
      }}
    />
  )
}

export const WebPageSchema = ({ name, description }) => {
  return (
    <Script
      id="app-ld-json-webpage"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: name,
            description: description,
            publisher: {
              '@type': 'Organization',
              name: 'Edworking',
            },
          },
          null,
          '\t'
        ),
      }}
    />
  )
}
export const ArticleSchema = ({ blog, url }) => {
  return (
    <Script
      id="app-ld-json-article"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': url,
            },
            headline: blog?.rawTitle,
            image: blog.cover,
            datePublished: new Date(blog.createdAt),
            dateModified: new Date(blog.updatedAt),
            author: [
              {
                '@type': 'Person',
                name: blog?.author?.firstname + ' ' + blog?.author?.lastname,
              },
            ],
            publisher: {
              '@type': 'Organization',
              name: 'Edworking',
            },
            articleBody: blog.article.replace(/<[^>]*>?/gm, ''),
          },
          null,
          '\t'
        ),
      }}
    />
  )
}
export const VideoSchema = ({ blog, url }) => {
  return (
    <Script
      id="app-ld-json-video"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': url,
            },
            name: blog?.rawTitle,
            description: blog.sumup,
            thumbnailUrl: [blog.cover],
            uploadDate: new Date(blog.createdAt),
            duration: blog.keywords,
            contentUrl: blog.url,
            publisher: {
              '@type': 'Organization',
              name: 'Edworking',
            },
          },
          null,
          '\t'
        ),
      }}
    />
  )
}
export const FAQSchema = ({ faqs }) => {
  if (faqs?.length) {
    return (
      <Script
        id="app-ld-json-faqs"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            {
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: [
                faqs.map((faq) => {
                  return {
                    '@type': 'Question',
                    name: faq.question,
                    acceptedAnswer: {
                      '@type': 'Answer',
                      text: faq.answer,
                    },
                  }
                }),
              ],
            },
            null,
            '\t'
          ),
        }}
      />
    )
  } else return null
}
export const BreadcrumbListSchema = ({ items }) => {
  return (
    <Script
      id="app-ld-json-breadcrumb"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              items.map((item, index) => {
                return {
                  '@type': 'ListItem',
                  position: index + 1,
                  name: item.name,
                  item: item.url,
                }
              }),
            ],
          },
          null,
          '\t'
        ),
      }}
    />
  )
}
